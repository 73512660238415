let spinnerCounter = 0;
function startSpinner() {
  if (spinnerCounter < 1) {
    $('#spinner-modal-trigger').trigger('click');
    spinnerCounter = 0;
  }
  spinnerCounter++;
}

function stopSpinner() {
  spinnerCounter--;
  if (spinnerCounter < 1) $('#spinner-modal button').trigger('click');
}

$(".from-date, .to-date, .existing-agent, .existing-status").change(function () {
  handleReqRes()
});

function handleReqRes() {
  let fromDate = $(".from-date").val()
  let toDate = $(".to-date").val()
  let agent = $(".existing-agent").val()
  let status = $('.existing-status').val()
  data = {
    from_date: fromDate,
    to_date: toDate,
    agent: agent,
    status: status
  }
  handleAJaxResponse(data)
}

function handleAJaxResponse(data) {
  startSpinner();
  $.ajax({
    type: "GET",
    url: window.location.pathname,
    dataType: "json",
    data: data,
    error: function (xhr, status, error) {
      stopSpinner();
      console.error("AJAX Error: " + status + error);
    },
    success: function (response) {
      stopSpinner();
      handleResponse(response)
    },
  });
}

function handleResponse(response) {
  var agentFunds = response.agent_funds;
  $(".response-body").empty(); // Clear existing rows
  $('.total-funds').text(0);
  if (agentFunds.length > 0) {
    $('.total-funds').text(agentFunds.length);
    var rows = "";
    for (var i = 0; i < agentFunds.length; i++) {
      const createdAt = new Date(agentFunds[i].created_at).toLocaleString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: 'UTC'
      });

      // Initialize fileDownloadButton for each row
      let fileDownloadButton = '';
      if (agentFunds[i].file_url && agentFunds[i].file_identifier) {
        fileDownloadButton = `
          <div class="flex gap-2 justify-center">
            <a href="${agentFunds[i].file_url}" download="${agentFunds[i].file_identifier}" class="btn btn-icon btn_primary">
              <span class="las la-file-download"></span>
            </a>
          </div>
        `;
      }
      rows +=
        "<tr>" +
        `<td class="text-center">${agentFunds[i].reference_no}</td>` +
        `<td class="text-center">${createdAt} UTC</td>` +
        `<td class="text-center">${agentFunds[i].agent_name}</td>` +
        `<td class="text-center">${agentFunds[i].fund_amount}</td>` +
        `<td class="text-center">${agentFunds[i].remarks}</td>` +
        `<td class="text-center">${humanizeStatus(agentFunds[i].status)}</td>` +
        `<td>${fileDownloadButton}</td>` +
        `<td class="text-center">` +
        `<a class="btn btn-icon btn_outlined btn_secondary ltr:ml-auto rtl:mr-auto lg:ltr:ml-0 lg:rtl:mr-0" href="${agentFunds[i].id}/receipt" target="_blank">` +
        `<span class="la la-print"></span>` +
        `</a>`;
      if (response.current_bo_user && response.can_update) {
        rows +=
          `<td>` +
          `<a class="btn btn-icon btn_outlined btn_secondary" href="/agent_funds/${agentFunds[i].id}/edit">` +
          `<span class="la la-pen-fancy"></span>` +
          `</a>` +
          `</td>`;
      }

      rows += "</td></tr>";
    }

    // Append all the rows to the table body and fade them in
    $(rows).hide().appendTo(".response-body").fadeIn();
  }
}

function humanizeStatus(status) {
  return status.split('_').map(function (word) {
    return word.toUpperCase();
  }).join(' ');
}